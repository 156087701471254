import {ServiceService} from "ellipse/service/ServiceService";
import {BaseRequestObject} from "ellipse/objects/BaseRequestObject";
import {BaseResultObject} from "ellipse/objects/BaseResultObject";
import {UnitTestIgnore} from "ellipse/test/UnitTestIgnore";

@UnitTestIgnore()
export class IServerIdentityManager {

	private server: ServiceService;

	constructor(retry: number = 0) {
		this.server = new ServiceService(retry);
	}

	public signup = (request : IServerIdentityManager.IdentitySignupRequest) : Promise<IServerIdentityManager.IdentitySignupResult> => {
		let promise = new Promise<IServerIdentityManager.IdentitySignupResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerIdentityManager.IdentitySignupResult = new IServerIdentityManager.IdentitySignupResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [request];
			this.server.request(listener, 'tools.ellipse.server.services.identity.IServerIdentityManager', '{2c3279b6-0726-4a07-996b-e906752ba701}', params);
		});
		return promise;
	}

	public login = (request : IServerIdentityManager.IdentityLoginRequest) : Promise<IServerIdentityManager.IdentityLoginResult> => {
		let promise = new Promise<IServerIdentityManager.IdentityLoginResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerIdentityManager.IdentityLoginResult = new IServerIdentityManager.IdentityLoginResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [request];
			this.server.request(listener, 'tools.ellipse.server.services.identity.IServerIdentityManager', '{3da55909-0c50-4cb1-90e2-b4771e2450f9}', params);
		});
		return promise;
	}

	public redirect = (request : IServerIdentityManager.IdentityRedirectRequest) : Promise<IServerIdentityManager.IdentityRedirectResult> => {
		let promise = new Promise<IServerIdentityManager.IdentityRedirectResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerIdentityManager.IdentityRedirectResult = new IServerIdentityManager.IdentityRedirectResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [request];
			this.server.request(listener, 'tools.ellipse.server.services.identity.IServerIdentityManager', '{16b6ffb8-5be4-4a6a-ab4f-ae52d20d3909}', params);
		});
		return promise;
	}

	public logout = (request : IServerIdentityManager.IdentityLogoutRequest) : Promise<IServerIdentityManager.IdentityLogoutResult> => {
		let promise = new Promise<IServerIdentityManager.IdentityLogoutResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerIdentityManager.IdentityLogoutResult = new IServerIdentityManager.IdentityLogoutResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [request];
			this.server.request(listener, 'tools.ellipse.server.services.identity.IServerIdentityManager', '{9a909bcc-d81c-4256-a70e-6548f64db678}', params);
		});
		return promise;
	}

}

export namespace IServerIdentityManager {

	export class IdentitySignupResult extends BaseResultObject {

		public javaType = () : string => { return "tools.ellipse.server.services.identity.IdentitySignupResult"; }

		public OAuthRedirect: string | undefined = undefined;

		public errorCode: IServerIdentityManager.IdentitySignupError | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.OAuthRedirect = this.typeString(this.OAuthRedirect);
			this.errorCode = this.typeEnum(IServerIdentityManager.IdentitySignupError, this.errorCode);
		}

	}

	export class IdentitySignupRequest extends BaseRequestObject {

		public javaType = () : string => { return "tools.ellipse.server.services.identity.IdentitySignupRequest"; }

		public identityProvider: IServerIdentityManager.IdentityProvider | undefined = undefined;

		public redirect: string | undefined = undefined;

		public redirectFailure: string | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.identityProvider = this.typeEnum(IServerIdentityManager.IdentityProvider, this.identityProvider);
			this.redirect = this.typeString(this.redirect);
			this.redirectFailure = this.typeString(this.redirectFailure);
		}

	}

	export class IdentityLoginResult extends BaseResultObject {

		public javaType = () : string => { return "tools.ellipse.server.services.identity.IdentityLoginResult"; }

		public OAuthRedirect: string | undefined = undefined;

		public errorCode: IServerIdentityManager.IdentityLoginError | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.OAuthRedirect = this.typeString(this.OAuthRedirect);
			this.errorCode = this.typeEnum(IServerIdentityManager.IdentityLoginError, this.errorCode);
		}

	}

	export class IdentityLoginRequest extends BaseRequestObject {

		public javaType = () : string => { return "tools.ellipse.server.services.identity.IdentityLoginRequest"; }

		public identityProvider: IServerIdentityManager.IdentityProvider | undefined = undefined;

		public redirect: string | undefined = undefined;

		public redirectFailure: string | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.identityProvider = this.typeEnum(IServerIdentityManager.IdentityProvider, this.identityProvider);
			this.redirect = this.typeString(this.redirect);
			this.redirectFailure = this.typeString(this.redirectFailure);
		}

	}

	export class IdentityRedirectResult extends BaseResultObject {

		public javaType = () : string => { return "tools.ellipse.server.services.identity.IdentityRedirectResult"; }

	}

	export class IdentityRedirectRequest extends BaseRequestObject {

		public javaType = () : string => { return "tools.ellipse.server.services.identity.IdentityRedirectRequest"; }

		public stateId: string | undefined = undefined;

		public redirectDomain: string | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.stateId = this.typeString(this.stateId);
			this.redirectDomain = this.typeString(this.redirectDomain);
		}

	}

	export class IdentityLogoutResult extends BaseResultObject {

		public javaType = () : string => { return "tools.ellipse.server.services.identity.IdentityLogoutResult"; }

		public OAuthRedirect: string | undefined = undefined;

		public errorCode: IServerIdentityManager.IdentityLogoutError | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.OAuthRedirect = this.typeString(this.OAuthRedirect);
			this.errorCode = this.typeEnum(IServerIdentityManager.IdentityLogoutError, this.errorCode);
		}

	}

	export class IdentityLogoutRequest extends BaseRequestObject {

		public javaType = () : string => { return "tools.ellipse.server.services.identity.IdentityLogoutRequest"; }

		public identityProvider: IServerIdentityManager.IdentityProvider | undefined = undefined;

		public redirect: string | undefined = undefined;

		public redirectFailure: string | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.identityProvider = this.typeEnum(IServerIdentityManager.IdentityProvider, this.identityProvider);
			this.redirect = this.typeString(this.redirect);
			this.redirectFailure = this.typeString(this.redirectFailure);
		}

	}

	export enum IdentitySignupError {
		PRIVILEGE_NOT_FOUND = "PRIVILEGE_NOT_FOUND",
		PRIVILEGE_NOT_PUBLICALLY_ALLOCABLE = "PRIVILEGE_NOT_PUBLICALLY_ALLOCABLE",
		CLIENTID_NOT_SPECIFIED = "CLIENTID_NOT_SPECIFIED",
		IDENTITY_PROVIDER_NOT_SPECIFIED = "IDENTITY_PROVIDER_NOT_SPECIFIED",
		SITE_IDENTIFIER_NOT_SPECIFIED = "SITE_IDENTIFIER_NOT_SPECIFIED",
		SITE_IDENTIFIER_DOES_NOT_EXIST = "SITE_IDENTIFIER_DOES_NOT_EXIST",
		SITE_IDENTIFIER_DOES_NOT_UNIQUELY_EXIST = "SITE_IDENTIFIER_DOES_NOT_UNIQUELY_EXIST",
		CREDENTIAL_NOT_FOUND = "CREDENTIAL_NOT_FOUND",
		IDENTITY_PROVIDER_NOT_FOUND = "IDENTITY_PROVIDER_NOT_FOUND",
		REDIRECT_NOT_SPECIFIED = "REDIRECT_NOT_SPECIFIED"
	}

	export enum IdentityProvider {
		GOOGLE = "GOOGLE",
		FACEBOOK = "FACEBOOK",
		AZURE_B2C = "AZURE_B2C",
		AWS_COGNITO = "AWS_COGNITO"
	}

	export enum IdentityLoginError {
		CLIENTID_NOT_SPECIFIED = "CLIENTID_NOT_SPECIFIED",
		REDIRECT_NOT_SPECIFIED = "REDIRECT_NOT_SPECIFIED",
		IDENTITY_PROVIDER_NOT_SPECIFIED = "IDENTITY_PROVIDER_NOT_SPECIFIED",
		SITE_IDENTIFIER_NOT_SPECIFIED = "SITE_IDENTIFIER_NOT_SPECIFIED",
		SITE_IDENTIFIER_DOES_NOT_EXIST = "SITE_IDENTIFIER_DOES_NOT_EXIST",
		SITE_IDENTIFIER_DOES_NOT_UNIQUELY_EXIST = "SITE_IDENTIFIER_DOES_NOT_UNIQUELY_EXIST",
		CREDENTIAL_NOT_FOUND = "CREDENTIAL_NOT_FOUND",
		IDENTITY_PROVIDER_NOT_FOUND = "IDENTITY_PROVIDER_NOT_FOUND"
	}

	export enum IdentityLogoutError {
		CLIENTID_NOT_SPECIFIED = "CLIENTID_NOT_SPECIFIED",
		REDIRECT_NOT_SPECIFIED = "REDIRECT_NOT_SPECIFIED",
		IDENTITY_PROVIDER_NOT_SPECIFIED = "IDENTITY_PROVIDER_NOT_SPECIFIED",
		SITE_IDENTIFIER_NOT_SPECIFIED = "SITE_IDENTIFIER_NOT_SPECIFIED",
		SITE_IDENTIFIER_DOES_NOT_EXIST = "SITE_IDENTIFIER_DOES_NOT_EXIST",
		SITE_IDENTIFIER_DOES_NOT_UNIQUELY_EXIST = "SITE_IDENTIFIER_DOES_NOT_UNIQUELY_EXIST",
		CREDENTIAL_NOT_FOUND = "CREDENTIAL_NOT_FOUND",
		IDENTITY_PROVIDER_NOT_FOUND = "IDENTITY_PROVIDER_NOT_FOUND"
	}

}