import React, { useState, useEffect } from "react";
import { create } from "zustand";
import IndexNavbar from "./index/IndexNavbar";
import IndexHtmlConversion from "./index/IndexHtmlConversion";
import IndexHelperCards from "./index/IndexHelperCards";
import IndexFooter from "./index/IndexFooter";
import IndexVideo from "./index/IndexVideo";
import { Transition } from "@headlessui/react";
import { Navigate } from "ellipse/navigate/Navigate";
import Logo from "./../shared/Logo";

export interface IndexProps {}

export interface IndexState {

	hasDemoVideo: boolean;
	hasFigmaSupport: boolean;
	prerelease: boolean;
	setPrerelease: (prerelease: boolean) => void;

}

export const useIndexState = create<IndexState>((set) => ({

	hasDemoVideo: false,
	hasFigmaSupport: false,
	prerelease: false,
	setPrerelease: (prerelease: boolean) => set({ prerelease: prerelease }),

}));

const Index: React.FC<IndexProps> = (props: IndexProps) => {

	const { prerelease, setPrerelease } = useIndexState();

	useEffect(() => {
		setPrerelease(false);
		// setPrerelease(Navigate.domain() === "designshift.dev");
	}, []);

	return (
		<>
			{!prerelease && <IndexNavbar />}
			{prerelease && (
				<div className="w-full flex justify-center pt-4">
					<Logo />
				</div>
			)}
			<div id="index-container" className="container mx-auto">
				<div className={`${prerelease ? "" : "pt-4"}`}>
					<div className="animate__animated animate__fadeIn">
						<IndexHtmlConversion />
					</div>
				</div>
				<div className="animate__animated animate__fadeInUp animate__delay-1s">
					<IndexHelperCards />
				</div>
			</div>
			{!prerelease && (
				<div className="animate__animated animate__fadeInUp animate__delay-1s">
					<IndexFooter />
				</div>
			)}
		</>
	);

};

export default Index;